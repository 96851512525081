import * as R from 'ramda';
import styled from 'styled-components';

import { getScreenSize, getThemeColor, getThemeFont, getThemeSize } from '../../../utils/theme';
import { Input, Logo, PrimaryButtonS } from '../common';

export const HeaderWrapperS = styled.div`
  width: 100%;
  padding: 14.5px;

  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

  background-color: ${getThemeColor(['white'])};
  z-index: 29;

  border-radius: 3px;

  @media (max-width: ${getScreenSize(['medium'])}) {
    padding: 18px 0px 0px;
    transform: none;
    left: 0;
    max-width: 100vw;
  }
`;

export const SearchInputWrapS = styled.div`
  position: relative;

  display: flex;

  svg {
    height: 1.5em !important;
    width: 1.5em !important;

    position: absolute;
    right: 8px;
    top: 18%;
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    svg {
      display: ${({ mobileInput }) => (mobileInput ? 'block' : 'none')};
    }
  }
`;

export const SearchInputS = styled(Input)`
  width: 100%;
  padding: 7px 35px 7px 20px;
  font-family: ${getThemeFont(['bold'])};

  @media (max-width: ${getScreenSize(['medium'])}) {
    display: none;
  }
`;

export const SearchMobileInputS = styled(Input)`
  width: 100%;
  padding: 9px 46px 8px 16px;
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }

  &:focus {
    background: ${getThemeColor(['greyBlueLight'])};
    border-color: ${getThemeColor(['greyLight'])};
  }
`;

export const EraseIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 118px;
  top: 18px;
  cursor: pointer;
  padding: 5px;
`;

export const LogoHeaderS = styled(Logo)`
  @media (max-width: ${getScreenSize(['medium'])}) {
    height: 22px;
  }
`;

export const MenuS = styled.ul`
  display: flex;
  align-items: center;

  line-height: ${R.propOr('30px', 'lineHeight')};

  @media (max-width: ${getScreenSize(['medium'])}) {
    display: none;
  }
`;

export const PrimaryButtonStyle = styled(PrimaryButtonS)`
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    background-color: ${getThemeColor(['red'])};
  }
`;

export const IconsWrapperS = styled.i`
  display: none;
  font-weight: bold;
  float: left;
  cursor: pointer;
  svg {
    width: 22px !important;
    height: 22px;
    color: ${getThemeColor(['violet'])};
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    display: inline-block;
  }
`;

export const BurgerIconS = styled(IconsWrapperS)`
  margin-right: 24px;
`;

export const SearchIconS = styled(IconsWrapperS)`
  margin-right: 16px;
`;

export const UserIconS = styled(IconsWrapperS)`
  margin-left: 16px;
  svg {
    height: 20px;
  }
`;

export const RedirectS = styled.a`
  margin: 0 26px 0 0;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 20px;
  }
`;

export const MobileSearchWrapperS = styled.div`
  display: none;
  box-shadow: 2px 0px 4px 0px #00000040;
  width: 100%;
  background-color: ${getThemeColor(['white'])};
  flex-direction: column;

  input {
    width: 100%;
    display: block;
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
`;

export const SearchWrapperS = styled.div`
  display: flex;
  position: relative;
  padding: 12px 20px 10px;
`;

export const SearchBtnS = styled(PrimaryButtonS)`
  position: relative;
  border-radius: 2px;
  font-family: ${getThemeFont(['regular'])};
  font-size: ${getThemeSize(['mediumText'])};
  font-weight: 400;
  line-height: 15.6px;
  padding: 8px 18px 9px;
  height: auto;
  min-width: 86px;
  margin: 1px 0;
`;

export const CompanyTitleS = styled.div`
  font-family: ${getThemeFont(['regular'])};
  font-size: ${getThemeSize(['mediumText'])};
  font-weight: 400;
  line-height: 15.6px;
  border-bottom: 1px solid ${getThemeColor(['grayBlue'])};
  padding: 12px 36px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${getThemeColor(['grayBlueOpacity'])};
  cursor: pointer;

  & span:nth-child(1) {
    display: inline;
    color: ${getThemeColor(['violet'])};
  }

  & span:nth-child(2) {
    display: inline;
    color: ${getThemeColor(['grayBlueOpacity'])};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const HeaderContentS = styled.div`
  @media (max-width: ${getScreenSize(['medium'])}) {
    padding: 0 24px 9px;
  }
`;
