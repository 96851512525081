/*The purpose of this helper to build routes which have dynamic parts.*/
import { routes } from '../../app/routes/routesConfig';

const replaceNameAndId = originalRoute => (id, name) =>
  originalRoute
    .replace(':id', id)
    .replace(':companyName', name.replace(/ /g, '-').replace(/\//g, '-').replace(/\\/g, '-'));

/* Route -> /companies/:id/:companyName
* @params
* id
* name
*  */
export const getCompanyRoute = replaceNameAndId(routes.COMPANY)

/* Route -> /companies/:id/:companyName
* @params
* id
* name
*  */
export const getCompanyEditRoute = replaceNameAndId(routes.COMPANY_EDIT)

export const getCompanyProfileAdminRoute = replaceNameAndId(routes.ADMIN.BASE + routes.ADMIN.COMPANY_PROFILE)
/* Route -> /companies/:id/:companyName
* @params
* id
* name
*  */
export const getReviewCreateRoute = replaceNameAndId(routes.REVIEW_CREATE)
