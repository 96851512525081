import React from 'react';
import { array, string } from 'prop-types'
import { compose, lifecycle, withState } from 'recompose';
import { t } from 'i18next';
import styled from 'styled-components';

import { InputLabel } from './TextInput';
import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { getThemeColor } from '../../../utils/theme';

export const RadioLabel = styled.label`
  position: relative;
  color: ${getThemeColor(['greyViolet'])};
  padding-left: 20px;
  margin-top: 10px;
  margin-right: 40px;

  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid;
    border-color: ${props => props.checked ? getThemeColor(['red']) : getThemeColor(['greyViolet'])};
    box-sizing: border-box;
  }

  &:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    display: ${props => (props.checked ? 'inline-block' : 'none')}
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${getThemeColor(['red'])};
    box-sizing: border-box;
  }
`;

export const RadioBox = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

const Radio = ({
  transKeyBase,
  radioGroupLabel,
  name,
  options,
  localState,
  setLocalState
}) => { 
  return (
  <DisplayFlexS direction="column">
    {radioGroupLabel &&
      <InputLabel>
        {radioGroupLabel}
      </InputLabel>
    }
    <DisplayFlexS aItems="center" alignItemsScreenMedium="center">
      {options && options.map((option, k) =>
        <RadioLabel checked={localState === option} key={k}>
          <RadioBox 
            key={k}
            name={name} 
            value={option}
            checked={localState === option}
            onChange={() => {
              setLocalState(localState = option);
            }}
          />
          {t( transKeyBase + '.' + name + '.' + option )}
        </RadioLabel>
      )}
    </DisplayFlexS>
  </DisplayFlexS>
  )
};

Radio.propTypes = {
  transKeyBase: string.isRequired,
  radioGroupLabel: string,
  name: string.isRequired,
  options: array.isRequired
}

export default compose(
  withState('localState', 'setLocalState', ({ initialState }) => initialState),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.localState !== this.props.localState) {
        this.props.onChange(this.props.localState);
      }
    }
  })
)(Radio);