import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as R from 'ramda';
import React, { Fragment, forwardRef } from 'react';
import styled from 'styled-components';

import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { getThemeColor, getThemeFont, getThemeSize, getScreenSize } from '../../../utils/theme';

import { FieldError } from './Error';




export const InputWrapper = styled.label`
  width: 100%;
  flex-basis: 100%;
  text-align: left;
  align-items: center;
  display: ${({ isHorizontal }) => isHorizontal ? 'flex' : 'inline'};
`;

export const InputLabel = styled.span`
  flex: 1;
  display: block;
  padding:  ${({ padding }) => padding || '0 0 5px'};
  
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  color: ${({ labelColor }) => getThemeColor([labelColor || 'darkerViolet'])};
  font-size: ${({ fontSize }) => fontSize || getThemeSize(['normalText'])};
  font-family: ${({ fFamily }) => getThemeFont([fFamily || 'regular'])};
  line-height: 14px;

  @media (max-width: ${getScreenSize(['medium'])}) {
    color: ${({ labelColor }) => getThemeColor([labelColor || 'violet'])};
    font-size: ${({ fontSize }) => fontSize || getThemeSize(['mediumText'])};
  }
`;

export const MarkRequiredLabel = styled.span`
  font-size: ${getThemeSize(['normalText'])};
  line-height: 14px;
  color: ${getThemeColor(['red'])}; 
`;

export const Input = styled.input`
  outline: none;
  transition: ${R.propOr('border 250ms ease', 'transaction')};
  display: ${R.propOr('block', 'display')};
  width: ${R.propOr('100%', 'width')};
  height: ${R.propOr('', 'height')};
  padding: ${R.propOr('8px 20px', 'padding')};
  margin: ${R.propOr('0', 'margin')};
  
  font-family: ${getThemeFont(['regular'])};
  font-size: ${R.propOr(getThemeSize(['normalText']), 'fontSize')};
  color: ${getThemeColor(['greyViolet'])}; 
  
  text-align: ${R.propOr('left', 'textAlign')};
  background: ${getThemeColor(['greyBlueLight'])};
  
  border: 1px solid ${({ borderColor = getThemeColor(['greyLight']), isError }) =>
  isError ? getThemeColor(['red']) : borderColor};
  
  border-radius: ${R.propOr('2px', 'borderRadius')};
  
  &:focus {
    background-color: ${getThemeColor(['white'])};
    
    border-color: ${({ isError }) => isError ? getThemeColor(['red']) : getThemeColor(['grayBlue'])};
  }
  &::placeholder {
    color: ${getThemeColor(['greyViolet'])};
    opacity: 0.5;
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    padding: 8px 14px;

    font-size: ${R.propOr(getThemeSize(['smallText']), 'fontSize')};
  }
`;

export const TextAreaS = styled.textarea`
  width: 100%;
  padding: 8px 20px;
  resize: none;
  border: 1px solid ${({ borderColor = getThemeColor(['greyLight']), isError }) =>
  isError ? getThemeColor(['red']) : borderColor};
  border-radius: ${R.propOr('2px', 'borderRadius')};
  
  background-color: ${getThemeColor(['greyBlueLight'])};
  color: ${getThemeColor(['greyViolet'])};
  font-size: ${getThemeSize(['normalText'])};
  font-family: ${getThemeFont(['regular'])};
  transition: background-color 1s;
  
  &:focus {
    background-color: ${getThemeColor(['white'])};
    border-color: ${({ isError }) => isError ? getThemeColor(['red']) : getThemeColor(['grayBlue'])};
    outline: none;
  }
  
  &::placeholder {
    color: ${getThemeColor(['greyViolet'])};
    opacity: 0.5;
  }
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  color: ${getThemeColor(['red'])};
  
  &:hover {
    cursor: pointer;
  }
`;

export const TextInput = forwardRef((
  { 
    name, 
    label, 
    isHorizontal, 
    isError, 
    error, 
    isRequired, 
    labelColor, 
    closeBtnHandler, 
    placeholder, 
    className,
    ...rest
  }, 
  ref) => <Fragment>
  <DisplayFlexS justify="space-between" className={className}>
    <InputWrapper {...{ isHorizontal, closeBtnHandler }}>
      {label && <InputLabel
        labelColor={labelColor}
        fontWeight={rest.fontWeight}
        fFamily={rest.fFamily}
        htmlFor={name}
      >{label}
      {isRequired && <MarkRequiredLabel>*</MarkRequiredLabel>}
      </InputLabel>}
      <DisplayFlexS aItems="center" alignItemsScreenMedium="center">
        <Input {...{ name, isError, ref, placeholder }} {...rest} />
        { closeBtnHandler && <DeleteIcon onClick={closeBtnHandler} icon='times'/>}
      </DisplayFlexS>
    </InputWrapper>
  </DisplayFlexS>
  {(isError && error) && <FieldError margin="5px 0 0" {...{ error }} />}
</Fragment>);

TextInput.displayName = 'TextInput';

export const TextArea = ({ name, label, isError, error, isRequired, labelColor, ...rest }) => (
  <Fragment>
    <InputLabel labelColor={labelColor} htmlFor={name}>
      {label}
      {isRequired && <MarkRequiredLabel>*</MarkRequiredLabel>}
    </InputLabel>
    <TextAreaS { ...{ name, isError } } {...rest} />
    {(isError && error) && <FieldError {...{ error }} />}
  </Fragment>
);

TextArea.displayName = 'TextArea';