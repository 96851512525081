import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { getThemeColor, getScreenSize, getThemeSize } from '../../../utils/theme';
import redChat from '../../assets/imgs/icons/chat-red.svg';

import { Text } from './Text';

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${R.propOr(getThemeColor(['red']), 'color')};
  margin: ${R.propOr('0px', 'margin')};
  padding-bottom: ${R.propOr('0', 'marginBottom')};
`;

const ErrorIcon = styled.img`
  margin-right: 6px;
  width: 20px;
`

const TextResponsiveS = styled(Text)`

  @media (max-width: ${getScreenSize(['medium'])}) {
    font-size: ${R.propOr(getThemeSize(['smallText']), 'fontSize')};
  }
`;

export const FieldError = ({
  icon,
  error,
  color="red",
  weight,
  size,
  align,
  ...rest
}) => error &&
  <ErrorWrapper {...rest}>
    <ErrorIcon src={redChat} alt="error-icon"/>
    <TextResponsiveS {...{ weight, color, size, align }}>
      {error}
    </TextResponsiveS>
  </ErrorWrapper>
;
