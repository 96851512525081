import React from 'react';
import { connect } from 'react-redux';
import { compose, setPropTypes } from 'recompose';
import { func, oneOf } from 'prop-types';
import { omit } from 'ramda';

import { modalsActions, modalsConstants } from '../../../../modules/modals';

import { CloseIconS, StyledModal } from '../modalCommonS';

import ConfirmationLayouts from './confirmationModalsLayout';

const ConfirmationModal = ({ setModalStateFor, opened, onApproved, onRejected, entityId, confirmationName, otherModalProps }) => (
  <StyledModal
    isOpen={opened}
    onEscapeKeydown={() => {
      setModalStateFor(confirmationName, { open: false });
      if (onRejected) {
        onRejected(entityId, otherModalProps);
      }
    }}
    style={{
      padding: '30px 10px'
    }}
  >

    {ConfirmationLayouts[confirmationName](setModalStateFor, confirmationName, onApproved, onRejected, entityId, otherModalProps )}

    <CloseIconS onClick={() => setModalStateFor(confirmationName, { open: false })} icon='times'/>
  </StyledModal>
);

export default compose(
  connect(({ modals }, { confirmationName }) => ({
    opened: modals[confirmationName].open,
    entityId: modals[confirmationName].id,
    otherModalProps: omit(['open', 'id'], modals[confirmationName])
  }),
    { setModalStateFor: modalsActions.setModalStateFor }),
  setPropTypes({
    confirmationName: oneOf(Object.values(modalsConstants.MODAL_TYPES)).isRequired,
    onApproved: func.isRequired,
    onRejected: func
  })
)(ConfirmationModal);
