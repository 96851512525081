export const companySorts = {
  POPULAR: {
    value: 'popular', // by default
    transPath: 'companies.sorts.mostPopular',
  },
  VIEWS: {
    value: 'views',
    transPath: 'companies.sorts.views',
  },
  NAME: {
    value: 'name',
    transPath: 'companies.sorts.name',
  },
};
